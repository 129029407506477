//Our own custom versions of messages shown by the Telerik ReportViewer used by dataquery
export class ViewerMessages {
	static english = {
		//noReport shows when we haven't given the viewer a report, which can happen initially, when running the query, or if no rows are returned.  So we use blank instead
		noReport: '',//'No report.'

		//messages we are overriding
		noReportInstance: 'No report instance.', //not sure when this comes up, using a similar message from .net
		noReportDocument: 'No report document.', //not sure when this comes up, using a similar message from .net

		//other messages for Documentation to review, which we might want to override, but are not yet
		loadingFormats: 'Loading...',
		loadingReport: 'Loading results...',
		preparingDownload: 'Preparing document to download. Please wait...',
		preparingPrint: 'Preparing document to print. Please wait...',
		errorLoadingTemplates: "Error loading the report viewer\'s templates. (templateUrl = '{0}').",
		errorServiceUrl: "Cannot access the reporting service. Please check your internet connection or contact Support.",
		loadingReportPagesInProgress: '{0} pages loaded so far...',
		loadedReportPagesComplete: 'Done. Total {0} pages loaded.',
		noPageToDisplay: "No page to display.",
		errorDeletingReportInstance: "Error deleting report instance: '{0}'.",
		errorRegisteringViewer: 'Error registering the viewer with the service.',
		noServiceClient: 'No serviceClient has been specified for this controller.',
		errorRegisteringClientInstance: 'Error registering client instance.',
		errorCreatingReportInstance: "Error creating report instance (Report = '{0}').",
		errorCreatingReportDocument: "Error creating report document (Report = '{0}'; Format = '{1}').",
		errorObtainingAuthenticationToken: 'Error obtaining authentication token.',
		clientExpired: "Your connection has expired. Please click 'Refresh'.",
		renderingCanceled: "Report processing was canceled.",

		//other messages refering to built in functionality we are not using deleted
	}
}
