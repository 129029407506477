<div class="report-viewer d-block position-relative h-100 bg-white">
	<div class="report-viewer__head d-flex align-items-center justify-content-between">
		<div class="report-viewer__title d-flex flex-column">
			<span class="title--overline ps-3 pt-1">
				DATA NAVIGATOR
			</span>
			<div [autoClose]="true" ngbDropdown placement="bottom-left" class="position-relative dropdown flex-fill">
				<button type="button"
					class="report-viewer__title-selection-menu dropdown-toggle position-relative w-100 d-flex justify-content-between align-items-center px-3 h-100"
					ngbDropdownToggle data-cy="DropdownMenuButton"
					[title]="_alliantDataQuery.currentDataQuery ? _alliantDataQuery.currentDataQuery.queryName : 'Loading...'">{{selectedDataQueryIndex
					!== undefined ?
					dataQueries.items[selectedDataQueryIndex].displayName : 'Select a Data Query'}}</button>
				<div ngbDropdownMenu class="p-2" style="width:330px" data-cy="DropdownMenu">
					<div *ngIf="isMenuLoaded; else menuLoading">
						<button *ngFor="let item of dataQueries?.items; let i=index" ngbDropdownItem class="dropdown-item"
							(click)="selectQuery(i)">{{item.displayName}}</button>
					</div>
				</div>
			</div>
		</div>
		<button class="btn btn-success btn-sm ms-auto me-3" (click)="runClick()"
			[disabled]="running || !isMenuLoaded || selectedDataQueryIndex === undefined">
			Run
		</button>
	</div>
	<div class="d-flex justify-content-end align-items-center p-2">
		<div class="d-flex justify-content-between flex-fill me-auto" style="max-width: 50%;">
			<ul class="pagination" *ngIf="pageCount > 1">
				<li class="page-item">
					<button type="button" class="btn btn-link page-link" aria-label="First" (click)="reportViewer.commands.goToFirstPage.exec()"
						[disabled]="!haveReport"><span aria-hidden="true">««</span></button>
				</li>
				<li class="page-item">
					<button type="button" class="btn btn-link page-link" aria-label="Previous" (click)="reportViewer.commands.goToPrevPage.exec()"
						[disabled]="!haveReport"><span aria-hidden="true">«</span></button>
				</li>
				<li *ngIf="currentPage - 2 > 1" class="page-item">
					<a class="cursor-pointer p-1" (click)="reportViewer.commands.goToFirstPage.exec()">1</a>
				</li>
				<li *ngIf="currentPage - 2 > 1" class="page-item">
					...
				</li>
				<li *ngIf="currentPage > 1" class="page-item">
					<a class="cursor-pointer p-1" (click)="reportViewer.commands.goToPrevPage.exec()">{{currentPage
						-1}}</a>
				</li>
				<li class="page-item">
					<strong class="p-1">{{currentPage}}</strong>
				</li>
				<li *ngIf="currentPage < pageCount" class="page-item">
					<a class="cursor-pointer p-1" (click)="reportViewer.commands.goToNextPage.exec()">{{currentPage
						+1}}</a>
				</li>
				<li *ngIf="currentPage + 2 < pageCount" class="page-item">
					...
				</li>
				<li *ngIf="currentPage + 2 < pageCount" class="page-item">
					<a class="cursor-pointer p-1" (click)="reportViewer.commands.goToLastPage.exec()">{{pageCount}}</a>
				</li>
				<li class="page-item">
					<button type="button" class="btn btn-link page-link" aria-label="Next" (click)="reportViewer.commands.goToNextPage.exec()"
						[disabled]="!haveReport"><span aria-hidden="true">»</span></button>
				</li>
				<li class="page-item">
					<button type="button" class="btn btn-link page-link" aria-label="Last" (click)="reportViewer.commands.goToLastPage.exec()"
						[disabled]="!haveReport"><span aria-hidden="true">»»</span></button>

				</li>
			</ul>
			<span class="ms-auto text-nowrap k-link k-menu-link" [hidden]="pageCount < 1">
				Jump to page:
				<input class="form-control form-control-sm d-inline-block ms-2" data-role="telerik_ReportViewer_PageNumberInput" type="number"
					style="width: 55px;">
				of
				<span data-role="telerik_ReportViewer_PageCountLabel" class="px-1"></span>
			</span>

		</div>
		<button type="button" class="btn btn-icon btn-info ms-1" title="Zoom Out" (click)="reportViewer.commands.zoomOut.exec()"
			[disabled]="!haveReport">
			<i class="far fa-search-minus"></i>
		</button>
		<button type="button" class="btn btn-icon btn-info ms-1" title="Zoom In" (click)="reportViewer.commands.zoomIn.exec()"
			[disabled]="!haveReport">
			<i class="far fa-search-plus"></i>
		</button>
		<div [autoClose]="'outside'" ngbDropdown placement="bottom-right">
			<button type="button" class="btn btn-icon btn-info ms-2 hide-caret" ngbDropdownToggle role="button" data-cy="DropdownMenuButton"
				title="Menu Options"><i class="far fa-bars"></i></button>
			<div ngbDropdownMenu class="p-2" style="width:250px;" data-cy="DropdownMenu">
				<button ngbDropdownItem class="dropdown-item" (click)="toggleViewMode();reportViewer.commands.togglePrintPreview.exec();"
					[disabled]="!ready">View
					{{viewMode === viewModeInteractive ? 'Print' : 'Interactive'}} Mode</button>
				<button ngbDropdownItem class="dropdown-item" (click)="reportViewer.commands.print.exec()" [disabled]="!haveReport">Print
					Report</button>
				<hr class="my-1">
				<h6 class="dropdown-header">EXPORT</h6>
				<button ngbDropdownItem class="dropdown-item" (click)="export('CSV')">CSV</button>
				<button ngbDropdownItem class="dropdown-item" (click)="export('XLS')">Excel</button>
				<button ngbDropdownItem class="dropdown-item" (click)="export('PDF')">PDF</button>
				<button ngbDropdownItem class="dropdown-item" (click)="export('DOCX')">Word</button>
				<button ngbDropdownItem class="dropdown-item" (click)="export('XPS')">XPS Document</button>
			</div>
		</div>
	</div>

	<div class="viewerHost">
		<tr-viewer *ngIf="!removeViewer" #reportViewer [containerStyle]="viewerContainerStyle" [serviceUrl]="serviceUrl" [viewMode]="viewMode"
			[scaleMode]="scaleMode" [scale]="scale" [authenticationToken]="getAuthToken()" [pageMode]="pageMode" [error]="viewerError"
			[pageReady]="syncPages" [keepClientAlive]="keepViewerAlive">
		</tr-viewer>
	</div>
</div>

<ng-template #menuLoading>
	<div class="m-3 d-flex justify-content-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>