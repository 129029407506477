import { Injectable } from "@angular/core";
import { AlliantDataQuery } from "../../common/services/alliant/alliant-data.models";

/**
 * Service for managing Alliant data queries.
 */
@Injectable()
export class AlliantDataQueryService {

	constructor() { }

	/**
	 * The current Alliant data query.
	 */
	currentDataQuery: AlliantDataQuery;

	/**
	 * Sets the value of a parameter in the current data query.
	 *
	 * @param parameterLabel The label of the parameter to set.
	 * @param value The value to set for the parameter.
	 */
	public setParameterValue(parameterLabel: string, value: any) {

		// Find the index of the parm in the array with a matching label
		const index = this.currentDataQuery.parms.findIndex(param => param.label === parameterLabel);

		// If a matching parm is found, update its value
		if (index !== -1) {
			this.currentDataQuery.parms[index].value = value;
			this.currentDataQuery.parms[index].isSelected = true;
		}

	}

	/**
	 * Resets the value of a parameter in the current data query.
	 *
	 * @param parameterLabel The label of the parameter to reset.
	 */
	public resetParameter(parameterLabel: string) {

		// Find the index of the parm in the array with a matching label
		const index = this.currentDataQuery.parms.findIndex(param => param.label === parameterLabel);

		// If a matching parm is found, mark it as not selected
		if (index !== -1) {
			this.currentDataQuery.parms[index].value = null;
    		this.currentDataQuery.parms[index].isSelected = false;
		}

	}
}
