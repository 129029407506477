import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelerikReportViewerComponent } from './telerik-report-viewer.component';
import * as i0 from "@angular/core";
/**
 * Represents the [NgModule](https://angular.io/docs/ts/latest/guide/ngmodule.html)
 * definition for the Telerik Report Viewer component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Grid module
 * import { TelerikReportingModule } from './telerik-reporting/telerik-reporting.module';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * @@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, TelerikReportingModule], // import Grid module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
var TelerikReportingModule = /** @class */function () {
  function TelerikReportingModule() {}
  TelerikReportingModule.ɵfac = function TelerikReportingModule_Factory(t) {
    return new (t || TelerikReportingModule)();
  };
  TelerikReportingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TelerikReportingModule
  });
  TelerikReportingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [[CommonModule]]
  });
  return TelerikReportingModule;
}();
export { TelerikReportingModule };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TelerikReportingModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [TelerikReportViewerComponent],
      declarations: [TelerikReportViewerComponent]
    }]
  }], null, null);
})();